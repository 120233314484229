<template>
	<div class="app">
		<titleComponent :title="title"></titleComponent>

		<reousComponent :data="data"></reousComponent>
		<div class="cl_choice">
			<van-button type="primary" class="cl_button" @click="getrefund(id)">取消申请</van-button>
		</div>

	</div>
</template>

<script>
	import reousComponent from './components/reous.vue'
	import titleComponent from '../title.vue'
	import { Toast } from 'vant'
	import { useRouter } from 'vue-router'
	import { getlist } from '@/api/about'
	import { layout } from '@/utils/layout'

	export default {
		name: 'detailsComponent',
		components: {
			reousComponent,
			titleComponent
		},
		data() {
			return {
				title: '预约详情',
				id: -1,
				data: {},
			}
		},
		created() {
			this.id = this.$route.query.id;
			layout();
			this.getlist();
		},
		methods: {
			getlist() {
				getlist(this.id).then(res => {
				if (res.data.code == 200) {
					let data = res.data.data;
					data.appointVisitTime = data.appointVisitTime + ' ' + data.appointStartTime;
					this.data = data;
				} else {
					Toast.error('网络错误');
					this.$router.push({path: '/about'});
				}
				})
			}
		},
		setup() {
			// 返回上一级
			const onClickLeft = () => history.back();

			let router = useRouter();
			// 点击跳转到提交订单页面，暂时不需要
			function getrefund(val) {
				console.log(val);
				router.push({path: '/refund', query: {id: val}});
			}
			return {
			onClickLeft,
			getrefund,
			};
		},
	}
</script>

<style scoped>
	.app {
		width: 100%;
		height: 100%;
    border: 1px solid #F9F9F9;
	}

	.cl_choice {
		width: 100%;
		position: fixed;
		z-index: 999;
		bottom: 0;
	}
	.cl_button {
		width: 100%;
		height: 5rem;
		border: none;
		/* font-weight: 600; */
		font-size: 2rem;
		color: white;
	}


</style>
