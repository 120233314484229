<template>
	<div class="ap">

		<van-cell-group inset>
			<van-field v-model="data.name" label="姓名" placeholder="请填写姓名" required label-class="cl_ddit" />
			<div class="cl_rusern">
				<p class="cl_quxi">性别：</p>
				<van-radio-group v-model="checked" direction="horizontal" @change="getsex($event)">
					<van-radio name="0">男</van-radio>
					<van-radio name="1">女</van-radio>
				</van-radio-group>
			</div>
			<van-field v-model="data.relationship" label="亲属关系" placeholder="请输入亲属关系" required label-class="cl_ddit" />
			<van-field v-model="data.phone" type="tel" label="手机号码" placeholder="请输入手机号" required
				label-class="cl_ddit" />
			<van-field v-model="userBed_" placeholder="暂无" label="床号" disabled label-class="cl_ddit" />
			<van-field v-model="userArea_" placeholder="暂无" label="病区" disabled label-class="cl_ddit" />
			<!-- <van-field v-model="userBed_" placeholder="暂无" label="床号" disabled label-class="cl_ddit" /> -->
		</van-cell-group>


		<van-button type="primary" size="large" @click="onConfirm">保存</van-button>

	</div>
</template>

<script>
	import { add } from '@/api/home';
	import {
		ref, watch
	} from 'vue';

	import { reactive } from 'vue';
	import { Toast } from 'vant';
	import { useRouter } from 'vue-router';
	export default {
		name: 'addComponent',
		props: {
			resdata: {
				required: true,
			}
		},
		setup(props) {

			const userBed_ = ref('userBed_');
			const userArea_ = ref('userArea_');
			watch(()=>props.resdata, (newValue) => {
				userBed_.value = newValue.userBed;
				userArea_.value = newValue.userArea;
			})

			let data = reactive({
				name: '',
				relationship: '',
				phone: '',
				bednum: '',
				recordnum: '',
				sex: '',
			});
			const checked = ref('0');
			function getsex(event) {
				data.sex = event;
			}
			let router = useRouter();
			function onConfirm() {
				if (data.phone == '' || data.name == '' || data.sex == '' || data.relationship == '') {
					Toast.fail('不能为空');
				} else {
					let rouse = {
						bindAccount: data.phone,
						bindName: data.name,
						bindSex: data.sex,
						bindRelation: data.relationship
					}
					add(rouse).then(res => {
						if (res.data.code == 200) {
							Toast.success('保存成功');
							router.push({ path: '/management' });
						} else {
							Toast.fail('保存失败');
						}
					})
				}
			}
			return {
				data,
				getsex,
				checked,
				onConfirm,
				userBed_,
				userArea_,
			};
		},

	}
</script>

<style scoped>
	.ap {
		width: 100%;
		margin-top: 7rem;
		position: relative;
	}

	::v-deep .van-cell-group {
		width: 80%;
		margin: 4rem auto 2rem;
		/* border: 1px solid red; */
	}

	::v-deep .van-field {
		font-size: 1.6rem;
		height: 6rem;
	}

	::v-deep .cl_ddit {
		width: 9rem;
		font-weight: 900;
	}

	/* 权限 */
	.cl_rusern {
		display: flex;
		width: 100%;
		margin: 1rem auto;
		/* border: 1px solid red; */
	}

	.cl_quxi {
		font-weight: 550;
		font-size: 1.6rem;
		margin-left: 2.5rem;
	}

	::v-deep .van-radio-group {
		font-size: 1.6rem;
		margin-left: 4rem;
	}

	::v-deep .van-radio:last-child {
		margin-left: 2rem;
	}

	::v-deep .van-button {
		position: absolute;
		bottom: -15rem;
		width: 70%;
		left: 50%;
		transform: translate(-50%);
		border-radius: 10rem;
	}
</style>