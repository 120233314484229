<template>
	<div class="app">
		<van-nav-bar :title="title" left-arrow @click-left="onClickLeft" class="cl_leftarrow" />
		<add-component v-if="flag" :resdata="sbdata"></add-component>
		<edit-component v-else :editdata="sbdata" :detalis="detalis" @getedit="getedit"></edit-component>
	</div>
</template>

<script>
	// import titleComponent from '../title.vue'
	import addComponent from './components/add.vue';
	import editComponent from './components/edit.vue';

	import { details, info, update } from '@/api/home'
	import { layout } from '@/utils/layout'
	import { Toast } from 'vant';
	export default {
		name: 'addtoComponent',
		components: {
			//   titleComponent,
			addComponent,
			editComponent,
		},
		data() {
			return {
				flag: false,
				val: -1,
				title: '',
				sbdata: {},
				detalis: {},
			}
		},
		created() {
			layout();
			this.val = this.$route.query.id;
			if (this.$route.query.id == undefined) {
				this.flag = true;
				this.getinfo();
				this.title = '添加用户';
			} else {
				this.flag = false;
				this.getinfo();
				this.getdetails();
				this.title = '用户详情';
			}
		},
		methods: {
			// 返回上一级
			onClickLeft() {
				this.$router.push({ path: '/management' });
			},
			//床号和病区
			getinfo() {
				info().then(res => {
					let obj = res.data;
					if (obj.code == 200) {
						const { userAge, userArea, userBed } = obj.data
						this.sbdata = { userAge, userArea, userBed }
					} else {
						Toast.fail('暂无详情');
					}
				})
			},
			//信息
			getdetails() {
				details(this.val).then(res => {
					if (res.data.code == 200) {
						this.detalis = res.data.data;
					} else {
						Toast.fail('暂无详情');
						this.$router.push({ path: '/management' });
					}
				})
			},
			// 编辑
			getedit(value) {
				let data = {
					bindId: this.val,
					bindName: value.bindName,
					bindAccount: value.bindAccount,
					bindSex: value.bindSex,
					bindRelation: value.bindRelation,
				}
				update(data).then(res => {
					if (res.data.code == 200) {
						Toast.success('修改成功');
						this.$router.push({ path: '/management' });
					} else {
						Toast.fail('修改失败');
					}
				})
			}
		},
		// setup() {
		// 	// 返回上一级
		// 	const onClickLeft = () => history.back();
		// 	return {
		// 	onClickLeft,
		// 	};
		// },
	}
</script>

<style scoped>
	.ap {
		width: 100%;
	}

	.cl_leftarrow {
		position: fixed;
		top: 0;
		z-index: 9999;
		width: 100%;
		font-size: 30rem;
		color: rgb(0, 0, 0);
		background-color: rgb(255, 255, 255);
	}

	::v-deep .van-nav-bar .van-icon {
		color: black !important;
	}
</style>