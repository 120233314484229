<template>
	<div class="ap">
<!--		<van-nav-bar :title="title" class="cl_leftarrow" />-->
		<van-pull-refresh
			v-model="loading"
			success-text="刷新成功"
			@refresh="onRefresh"
			>
			<div class="cl_one_refs">
        <p class="cl_p cl_onep">请选择日期</p>
        <apptimeComponent :tabledata="tabledata" @formdata="passval" @loacalData="loacalData"></apptimeComponent>
      </div>
      <div class="cl_one_refs" v-show="flag">
        <p class="cl_p cl_onep">请选择时间段</p>
        <amdataComponent :buildata="formdata" @status="onstatus" @bourdata="bourdata"></amdataComponent>
      </div>
			<div class="cl_button" v-show="flag"><van-button type="primary" :disabled="stats" @click="nextStep">下一步</van-button></div>
		</van-pull-refresh>
	</div>
</template>

<script>
	import apptimeComponent from './components/apptime.vue'
	import amdataComponent from './components/amdate.vue'
	import { ruledate } from '@/api/appointment'

	export default {
		name: 'appointmentComponent',
		components: {
			apptimeComponent,
			amdataComponent,
		},
		data() {
			return {
        flag: false,
				title: '我要预约',
				stats: true,
				loading: false,
				brdata: {}, //预约时间
				formdata: {},
				data: [],
				tabledata: [
				{

						id: 1,
					},
					{
						id: 2,
					},
					{
						id: 3,
					},
					{
						id: 4,
					},
					{
						id: 5,
					},
					{
						id: 6,
					},
					{
						id: 7,
					}
				]
			}
		},
		created() {
			this.inform();
			this.infodata();
		},
		methods: {
			nextStep() {
				this.$router.push({path: '/payment', query: {row: encodeURIComponent(JSON.stringify(this.brdata))}});
			},
			onstatus(val) {
				this.stats = val;
			},
			passval(val) {
				this.formdata = val;
			},

			inform() {
				// 获取未来七天是否可预约
				ruledate().then(res => {
					if (res.data.code == 200) {
						this.data = res.data.data;
						for (let i = 0; i < 7; i++) {
							this.tabledata[i].status = this.data[i];
						}
					}
			})
			},
			infodata() {
				var date = new Date();
				var week = date.getDay();
				// var mounth = date.getMonth() + 1;
				var days = date.getDate();
				for (let i = 0; i < 7; i++) {
					switch(week) {
						case 0:
							this.tabledata[i].week = '周日',
							week += 1;
							break;
						case 1:
							this.tabledata[i].week = '周一',
							week += 1;
							break;
						case 2:
							this.tabledata[i].week = '周二',
							week += 1;
							break;
						case 3:
							this.tabledata[i].week = '周三',
							week += 1;
							break;
						case 4:
							this.tabledata[i].week = '周四',
							week += 1;
							break;
						case 5:
							this.tabledata[i].week = '周五',
							week += 1;
							break;
						case 6:
							this.tabledata[i].week = '周六',
							week += 1;
							break;
					}
					if (week == 7) {
						week = 0;
					}
				// 	if (mounth == 1 || mounth == 3 || mounth == 5 || mounth == 8 ) {
						this.tabledata[i].days = days,
						days += 1;
						if (days == 31) {
							days = 1;
						}
				// 	} else {
				// 		this.tabledata[i].days = days,
				// 		days += 1;
				// 		if (days == 30) {
				// 			days = 1;
				// 		}
				// 	}
				}
			},
			onRefresh() {
				setTimeout(() => {
					this.inform();
					this.infodata();
					this.loading = false;
				}, 1000);
			},
			loacalData(value) {
				this.brdata.visitTime = value.lookingDate;
        this.flag = true;
			},
			bourdata(value) {
				this.brdata.startStamp = value.substring(0,5);
				this.brdata.endStamp = value.substring(value.length-5,value.length);
			}
		}
	}

</script>

<style scoped>
	.ap {
		width: 100%;
    height: 100%;

    /*position: relative;*/
    /*border: 1px solid red;*/
	}
	/*.cl_leftarrow {*/
	/*	position: fixed;*/
	/*	top: 0;*/
	/*	z-index: 9999;*/
	/*	width: 100%;*/
	/*	font-size: 30rem;*/
	/*	color: rgb(0, 0, 0);*/
	/*	background-color: rgb(255, 255, 255);*/
	/*}*/
  .van-pull-refresh {
    width: 100%;
    height: 90%;
    overflow: auto;
    margin: 0 auto;
    /*position: relative;*/
    /*border: 1px solid orange;*/
  }
  .cl_one_refs {
    width: 95%;
    background-color: white;
    border-radius: 1.2rem;
    margin: 1rem auto;
    padding-bottom: 0.5rem;
  }
	.cl_p {
		font-size: 2rem;
		margin-left: 1rem;
    font-size: 1.6rem;
    color: #212121;
    border-bottom: 0.5px solid #EEEEEE;
    padding: 1rem 0 1rem 1rem;
	}
	.cl_onep {
		/*margin-top: 6rem;*/
	}

	.cl_button {
		width: 70%;
		height: 5rem;
    margin: 3rem auto 5rem;
		/*left: 50%;*/
    /*transform: translateX(-50%);*/
    /*position: absolute;*/
	}
	.cl_button button {
		border-radius: 50px;
		width: 100%;
		height: 5rem;
		background-color: royalblue;
		border: none;
		color: white;
		font-size: 2rem;
	}
</style>
