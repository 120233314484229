<template>
	<div class="ap">
			<van-search v-model="value" placeholder="请输入搜索关键词" class="cl_search" />
			<div class="cl_plus" @click="getadd">＋</div>
	</div>
</template>

<script>
	// import { ref } from 'vue';
	
	export default {
		name: 'searchComponent',
		// setup() {
		// 	const value = ref('');
			
		// 	return { value };
		// },
		data() {
			return {
				value: '',
			}
		},
		watch: {
			value: function(val) {
				this.$emit('search',val)
			}
		},
		methods: {
			getadd() {
					this.$router.push({path: '/adduser'});
			}
		}
	};
</script>

<style scoped>
	.ap {
		width: 95%;
		margin: 6rem auto 0;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
	.cl_search {
		width: 80%;
	}
	.cl_plus {
		width: 3rem;
		height: 3rem;
		border-radius: 50%;
		border: 1px solid black;
		text-align: center;
		line-height: 3rem;
		font-size: 3rem;
		font-weight: 400;
		margin-left: 1rem;
	}
</style>
