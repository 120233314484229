<template>
	<div class="ap">
		<van-nav-bar :title="title" left-arrow @click-left="onClickLeft" class="cl_leftarrow" />

		<div class="cl_brod">
			<!-- <img src="../../assets/1000.jpg" v-if="!flag1" alt="">
			<van-count-down v-if="!flag1" :time="time" @finish="onFinish"/> -->
			<video id="id_video" controls autoplay muted width="100%" height="4rem">
				<source :type="'video/mp4' || 'video/avi' || 'video/mov'"/>
			</video>
		</div>
    <van-button round type="primary"  @click="onClickLeft">结束探视</van-button>
		<rtc-view v-if="!flag" appointment-id="26" user-name="张三" :user-id="id" @end="end"/>
	</div>
</template>

<script>
import { appoint, getrecord } from '@/api/video';
import { Dialog, Toast } from 'vant';
import {sendSms} from "@/api/appointment";
	// import { ref } from 'vue';
	export default {
		name: 'broadcastComponent',
		data() {
			return {
				id: -1,
				title: '探视直播',
				flag: false,
				// video: '',
				// flag1: false,
			}
		},
		created() {
			this.id = this.$route.query.id;
			this.getlist();
		},
		methods: {
			getlist() {
				getrecord(this.id).then(res => {
					if (res.data.code == 200) {
						this.flag = res.data.data.videoState;
						document.getElementById("id_video").src = res.data.data.videoUrl;
					} else if(res.data.code == 400) {
						Toast.fail('暂未录制视频')
						history.back();
					} else {
						Toast.fail('网络错误')
						history.back();
					}
			})
			},
			// 结束观看
			onClickLeft() {
				Dialog.confirm({
						// title: '标题',
					message: '是否结束观看探视回放！',
				})
				.then(() => {
          appoint(this.id).then(res => {
            console.log(this.id,res);
            if (res.data.code == 200) {
              this.$router.push({path: '/about'})
              setTimeout(() => {
                const mobile = localStorage.getItem('mobile')
                console.log(mobile);
                const info = '【远程探视】探视结束，请对本次服务做出评价!';
                sendSms(mobile,info).then(res => {
                  console.log(res);
                  // if (res.data.code == 200) {
                  //
                  // }
                })
              },1000)
            } else {
              Toast.fail('网络错误，请重新退出！')
            }
          })
				})
			},
			// onFinish() {
			// 	if (this.flag1) {
			// 		this.flag1 = false;
			// 	} else {
			// 		this.flag1 = true;
			// 	}
			// }
		}

	}
</script>

<style scoped>
	.ap {
		width: 100%;
	}

	.cl_leftarrow {
		position: fixed;
		top: 0;
		z-index: 9999;
		width: 100%;
		font-size: 30rem;
		color: rgb(0, 0, 0);
		background-color: rgb(255, 255, 255);
	}
	::v-deep .van-nav-bar .van-icon {
		color: black !important;
	}
	/* 直播 */
	.cl_brod {
		margin-top: 4.7rem;
		width: 100%;
		/* background: ur/* l('../../assets/1000.jpg') no-repeat;
		background-size: 100% auto; */
		height: auto;
		position: relative;
	}
	.cl_brod img {
		width: 100%;
		height: auto;
	}
	::v-deep .van-count-down {
		position: absolute;
		font-size: 2rem;
		top: 1rem;
		left: 1rem;
	}
	#id_video {
		width: 100%;
		height: auto;
	}
  ::v-deep .van-button {
    width: 80%;
    border: none;
    font-weight: 600;
    font-size: 1.5rem;
    color: white;
    background-color: #DF1642;
    position: fixed;
    left: 50%;
    transform: translate(-50%);
    bottom: 30rem;
  }
</style>
