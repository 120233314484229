import request from '@/utils/request'

export function obtain() {
    return request({
      url: 'client/appoint/obtain',
      method: 'post'
    })
  }

  // 日期展示
  export function ruledate() {
    return request({
      url: 'client/rule/date',
      method: 'post'
    })
  }

  // 时间段展示
  export function ruleinfo(data) {
    return request({
      url: 'client/rule/info',
      method: 'post',
      data
    })
  }

// 短信接口
export function sendSms(mobile,content) {
  return request({
    url: '/client/user/sendSms/' + mobile + '?content=' + content,
    method: 'post',
  })
}
