<template>
	<div class="ap">
		<p>我的绑定</p>
		<div class="cl_subscribe" @click="getuser">
			<span><img src="../../../../public/image/binding.png" alt=""></span>
			<span>家人绑定</span>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'bindingComponent',
		data() {
			return {
				
			}
		},
    methods: {
      getuser() {
        this.$router.push({path: '/management'});
      }
    }
	}
</script>

<style scoped>

		
	.ap {
		width: 95%;
		margin: 2rem auto;
	}
	.ap>p {
		font-size: 2rem;
		margin-left: 1rem;
		font-weight: 600;
	}
	.cl_subscribe {
		width: 95%;
		margin: 2rem auto;
		border-bottom: 1px solid black;
	}
	.cl_subscribe>span img {
		width: 3rem;
	}
	.cl_subscribe span:nth-child(2) {
		color: black;
		line-height: 4rem;
		margin-left: 1rem;
		font-size: 1.5rem;
		display: inline-block;
	}
</style>