<template>
	<div class="ap">
		<van-cell-group inset>
			<van-field label="预约人" :model-value="data.userName" readonly colon class="cl_data_field" />
			<van-field label="电话" :model-value="data.userAccount" colon readonly class="cl_data_field" />
			<van-field label="床号" :model-value="data.userBed" colon readonly class="cl_data_field" />
			<van-field label="病区" :model-value="data.userArea" colon readonly class="cl_data_field" />
			<van-field label="预约时间" :model-value="data.appointVisitTime" colon readonly class="cl_data_field" />
		</van-cell-group>
	</div>
</template>

<script>

	export default {
		name: 'reousComponent',
		props: {
			data: Object
		},
	};
</script>

<style scoped>
	.ap {
		width: 100%;
		margin-top: 6rem;
	}

	.cl_data_field {
		font-size: 1.7rem;
		font-weight: 400;
		height: 6rem;
	}
</style>
