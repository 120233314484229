<template>
	<div class="ap">
		<p>我的预约</p>
		<div class="cl_subscribe">
			<div @click="getBeall(1)" >
				<p><span class="iconfont">&#xe628;</span></p>
				<p>全部</p>
			</div>
			<div @click="getBeall(2)" >
				<p><span class="iconfont">&#xe604;</span></p>
				<p>待探视</p>
			</div>
			<div @click="getBeall(3)" >
				<p><span class="iconfont">&#xe615;</span></p>
				<p>已结束</p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'subscribeComponent',
		data() {
			return {

			}
		},
    methods: {
      getBeall(val) {
        sessionStorage.setItem('id',val);
        this.$router.push({path: '/mytask'});
      }
    }
	}
</script>

<style scoped>
	@font-face {
		font-family: "iconfont logo";
		src: url('https://at.alicdn.com/t/font_985780_km7mi63cihi.eot?t=1545807318834');
		src: url('https://at.alicdn.com/t/font_985780_km7mi63cihi.eot?t=1545807318834#iefix') format('embedded-opentype'),
		url('https://at.alicdn.com/t/font_985780_km7mi63cihi.woff?t=1545807318834') format('woff'),
		url('https://at.alicdn.com/t/font_985780_km7mi63cihi.ttf?t=1545807318834') format('truetype'),
		url('https://at.alicdn.com/t/font_985780_km7mi63cihi.svg?t=1545807318834#iconfont') format('svg');
	}
	.iconfont {
		font-family: "iconfont" !important;
		font-size: 20px;
		font-style: normal;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

	}

	.ap {
		width: 95%;
		margin: 2rem auto;
	}
	.ap>p {
		font-size: 2rem;
		margin-left: 1rem;
		font-weight: 600;
	}

	.cl_subscribe {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		width: 100%;
		margin: 1rem auto;
		background-color: white;
    border-radius: 20px;
	}
	.cl_subscribe>div {
		text-align: center;
	}
</style>
