<template>
	<div class="cl_morning">
			<div class="cl_flex" :class="[current == k ? 'currecl_datime a': 'cl_flex']" v-for="(item,k) in data" :key="k" @click="getment(k,item.status,item.dateTime)">
				<div>{{item.dateTime}}</div>
				<div v-if="item.status == true" class="cl_budkn">可约</div>
				<div v-if="item.status == false" style="color: red;">不可约</div>
			</div>
	</div>
</template>

<script>
import { Toast } from 'vant';


	export default {
		name: 'amdataComponent',
		props: {
			buildata: {
				required: true,
			}
		},
		data() {
			return {
				current: -1,
				// data: [],
			}
		},
		computed: {
			data(){
				let resdata = this.buildata.interval;
				if(!resdata){
					return [];
				}
				return resdata.map((item)=>({status: item.allow, dateTime: item.startTime + ' - ' + item.endTime}));
			}
		},
		methods: {
			getment(k,state,data) {
				if (state) {
					this.current = k;
					this.$emit('status',false);
					this.$emit('bourdata',data);
				} else {
					Toast.fail('这个不能预约');
				}
			}
		}
	}
</script>

<style scoped>
	.cl_morning {
		width: 90%;
		margin: 0 auto;
		/*background-color: whitesmoke;*/
		border-radius: 20px;
		margin: 2rem auto;
		padding-top: 1rem;

    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
	}
	.cl_morning p {
		font-size: 2rem;
		margin-left: 1rem;
	}
	.cl_flex {
		padding: 0.8rem 1rem;
		background-color: whitesmoke;
		width: 30%;
		border-radius: 20px;
		text-align: center;
		margin-bottom: 1.5rem;
		margin-left: 1rem;
		margin-right: 1rem;
		font-size: 1rem;
	}
	.cl_flex div:first-child {
		margin-bottom: 0.5rem;
	}
	.currecl_datime {
		border: orange;
	}
 .a {
		/*border: 1px solid orange;*/
		color: white;
    background-color: #18A957;
	}
  .a .cl_budkn {
    color: white;
  }
.cl_budkn {
  color: blue;
}


</style>
