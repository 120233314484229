<template>
	<div class="sap">
		<titleComponent :title="title"></titleComponent>

		<endComponent :data="data" :image="image"></endComponent>

    <div class="cl_danger">
      <van-button  @click="getevaluate(data.appointId,data.appointVisitTime)">待评价</van-button>
      <van-button type="danger" @click="getplayback(data.appointId)">探视回放</van-button>
    </div>

  </div>
</template>

<script>
	import { getavatar, getlist } from '@/api/about';
  import titleComponent from '../title.vue'
	import endComponent from './components/end.vue'
	export default {
		name: 'endetailsComponent',
		components: {
			titleComponent,
      endComponent,
		},
		data() {
			return {
				id: -1,
				title: '已结束',
        image: '',
        data: {},
			}
		},
		created() {
			this.id = this.$route.query.id;
      this.getlis();
		},
    methods: {
      getevaluate(val,time) {
        this.$router.push({path: '/evaluate',query: {id: val,visitTime: time}});
      },
      getplayback(val) {
        this.$router.push({path: '/playback',query: {id: val}});
      },
      getlis() {
        getlist(this.id).then(res => {
          if (res.data.code == 200) {
            this.data = res.data.data;
            this.data.appointVisitTime = res.data.data.appointVisitTime + ' ' + res.data.data.appointStartTime;
          }
        });
        getavatar().then(res => {
          if(res.data.code == 200) {
            this.image = res.data.data.avatarUrl;
          }
        })
      }
    }
	}
</script>

<style scoped>
	.sap {
    width: 100%;
    height: 100%;
    border: 1px solid #F9F9F9;
	}
  .cl_danger {
    width: 90%;
    position: fixed;
    left: 50%;
    transform: translate(-50%);
    bottom: 20rem;
    display: flex;
    justify-content: space-between;
  }
  ::v-deep .van-button {
    width: 47%;
    border: none;
    font-weight: 600;
    font-size: 1.5rem;
    color: white;
    border-radius: 20px;
  }
  .cl_danger .van-button:first-child {
    background-color: #18A957;
  }
  .cl_danger .van-button:last-child {
    background-color: #FFBB38;
  }
</style>
