<template>
	<div class="ap">
			<div class="cl_maxres">
        <div class="cl_ribect">
          退款详情
        </div>
          <van-cell-group inset class="cl_max_group">
            <van-field label="退款人" :model-value="evaluate.name" colon readonly label-width="6rem" />
            <van-field label="退款原因" :model-value="evaluate.reason" colon readonly label-width="6rem" />
            <van-field label="退款金额" :model-value="evaluate.money" colon readonly label-width="6rem" />
          </van-cell-group>
      </div>
	</div>
</template>

<script>

	export default {
		name: 'detailsComponent',
		props: {
      data: {
        required: true,
        type: Object,
      }
    },
		data() {
			return {
        evaluate: {},
			}
		},
    mounted() {
      this.evaluate = this.data;
    }

  };
</script>

<style scoped>
	.ap {
		width: 98%;
		margin: 2rem auto 0;
    /*border: 1px solid red;*/
    border-radius: 80px;
    font-size: 1.5rem;
	}
  /*探视对象*/
  .cl_maxres {
    width: 95%;
    margin: 0 auto;
    border-radius: 50px;
    background-color: #f4f3f3;
    height: auto;
    padding: 2rem 0 0;
    box-sizing: border-box;
  }
  .cl_ribect {
		font-size: 1.5rem;
		margin-bottom: 1rem;
		padding-bottom: 1rem;
		border-bottom: 1px solid black;
    text-indent: 2em;
	}

  .van-cell-group {
    width: 60%;
  }
  .van-field {
    background-color: #f4f3f3;
  }

</style>

