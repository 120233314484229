<template>
	<div class="ap">
    <van-nav-bar :title="title" left-arrow @click-left="onClickLeft" class="cl_leftarrow" />

    <prog-component :data="data2"></prog-component>
		<details-component :data="data1"></details-component>


  </div>
</template>

<script>
	// import titleComponent from '../title.vue'
	import progComponent from './components/prog.vue'
	import detailsComponent from './components/details.vue'
	export default {
		name: 'progressComponent',
		components: {
			// titleComponent,
      progComponent,
      detailsComponent,
		},
		data() {
			return {
				id: -1,
				title: '退款进度',
        data1: {
          id: 1,
          name: '张三',
          money: '35',
          reason: '网络错误',
        },
        data2: [
          {
            id: 1,
            title: '提交申请',
            text: '您的退款已申请成功，待客服审核',
            time: '2022-07-06 10:20',
          },
          {
            id: 2,
            title: '退款成功',
            text: '退款金额退回至您的账户，请注意查收',
            time: '2022-07-06 10:20',
          }
        ],
			}
		},
		mounted() {
			this.id = this.$route.query.id;
		},
    methods: {
      onClickLeft() {
        this.$router.push({path: '/about'});
      }
    }
	}
</script>

<style scoped>
	.ap {

	}
  .cl_leftarrow {
		position: fixed;
		top: 0;
		z-index: 9999;
		width: 100%;
		font-size: 30rem;
		color: rgb(0, 0, 0);
		background-color: rgb(255, 255, 255);
	}
  ::v-deep .van-nav-bar .van-icon {
		color: black !important;
	}
</style>
