<template>
	<div class="app">
		<logoComponent :title="title"></logoComponent>

			<van-form @submit="onSubmit">
        <div class="cl_form">
        <van-cell-group inset>
					<van-field v-model="username" name="username" label="用户名" colon placeholder="用户名"
						:rules="[{ required: true, message: '请填写用户名' }]" />
					<van-field v-model="password" type="password" name="password" label="密码" colon placeholder="密码"
						:rules="[{ required: true, message: '请填写密码' }]" />
				</van-cell-group>
        </div>
        <div style="margin: 16px;">
					<div class="cl_authentication" @click="authen">用户认证</div>
					<van-button round block type="primary" native-type="submit">提交</van-button>
				</div>
			</van-form>
		<!-- <van-dialog /> -->
	</div>
</template>

<script>
	import logoComponent from './components/logo.vue'
	import {
		ref
	} from 'vue';
	// import { reactive } from 'vue';
	import { useRouter } from 'vue-router';
	import { Dialog, Toast } from 'vant';

	import { login } from '@/api/login'
	import { setToken } from '@/utils/auth'
	export default {
		name: 'loginComponent',
		components: {
			logoComponent,
		},
		setup() {
			const title = ref('登录');
			const username = ref('');
			const password = ref('');
			const router = useRouter();
			function authen() {
				router.push({path: '/loginauth'});
			}
			const onSubmit = (values) => {
				const data = {
					userAccount: values.username,
					userPassword: values.password,
				}
				login(data).then(res => {
					const data = res.data;
					if (data.code == 200) {
						setToken(data.data.token);
            localStorage.setItem('mobile', values.username)
						router.push({path: '/about'});
						Toast.success('登录成功');
					} else {
						Dialog.alert({
							message: '账号或密码错误！',
						})
					}
				})
			};

			return {
				title,
				username,
				password,
				onSubmit,
				authen,
			};
		},


	};
</script>

<style scoped>
	.app {
		/* background-image: url('../../../public/image/c63e013cea559549c78a30ddf.jpg');
		background-repeat: no-repeat; */
		position: relative;
		height: 100%;
    border: 1px solid #F9F9F9;
	}
	.cl_form {
		width: 90%;
		margin: 8rem auto 0;
    padding: 2rem 0;
    background-color: white;
    border-radius: 20px;
	}
	.cl_authentication {
		margin-bottom: 2rem;
		float: right;
		font-size: 2rem;
		font-weight: 500;
		color: rgb(118, 119, 119);
		font-family: PingFangHK-Regular;
	}
	::v-deep .van-button {
		width: 70%;
		/* position: absolute; */
		bottom: -15rem;
		left: 50%;
		transform: translate(-50%);
	}
</style>
