import request from '@/utils/request'

// 用户信息
export function info() {
    return request({
      url: 'client/user/info',
      method: 'post',
    })
  }

  // 绑定用户信息列表
export function list(data) {
  return request({
    url: 'client/bind/list',
    method: 'post',
    data,
  })
}

//   添加绑定接口
  export function add(data) {
    return request({
      url: 'client/bind/add',
      method: 'post',
      data
    })
  }

  //   添加删除接口
  export function remove(id) {
    return request({
      url: 'client/bind/remove/' + id,
      method: 'post',
    })
  }

  //   绑定信息接口
  export function details(id) {
    return request({
      url: 'client/bind/list/' + id,
      method: 'post',
    })
  }

   //   绑定更新接口
   export function update(data) {
    return request({
      url: 'client/bind/update',
      method: 'post',
      data,
    })
  }
  
   //   预约我的接口
   export function getmine(state) {
    return request({
      url: 'client/appoint/mine/'+ state,
      method: 'post',
    })
  }

    //   评价接口
    export function evaluate(data) {
      return request({
        url: 'client/evaluate/add',
        method: 'post',
        data
      })
    }
	
	//   评价信息接口
	export function getinfo(id) {
		return request({
			url: 'client/evaluate/info/' + id,
			method: 'post',
		})
	}