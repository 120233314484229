<template>
r<div class="app">
		<titleComponent :title="title"></titleComponent>

		<reousComponent :val="val"></reousComponent>
	
</div>
</template>

<script>
	import reousComponent from './components/reous.vue'
	import titleComponent from '../title.vue'
  // import {ref} from 'vue'

	export default {
		name: 'refundComponent',
		components: {
			reousComponent,
			titleComponent,
		},
		data() {
			return {
				title: '退款申请',
				val: -1,
			}
		},
		mounted() {
			this.val = this.$route.query.id;
		},
		setup() {
			// 返回上一级
			const onClickLeft = () => history.back();
			return {
				onClickLeft,
			};
		},

  }
</script>

<style scoped>
	.ap {
		
	}
	/deep/ .van-notice-bar {
		margin-top: 5rem;
		font-size: 1.6rem;
		height: 5rem;
	}

</style>
