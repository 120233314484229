<template>
	<div class="ap">
			<div class="cl_visitime">
				<div class="cl_flex"><span><img src="../../../assets/tr6.png" alt="" style="width: 1.8rem;height: 1.8rem"></span><span>待探视</span></div>
				<van-count-down :time="unix" format="DD 天 HH 时 mm 分 ss 秒" @finish="onFinish" />
			</div>
			<div class="cl_maxres">
        <div class="cl_max_round">
          <div class="cl_round">
            <img :src="image" />
            <p>{{data.userName}}</p>
          </div>
          <van-cell-group inset class="cl_max_group">
            <van-field label="账号" :model-value="data.userAccount" readonly class="cl_group" label-width="5rem" />
            <van-field label="床号" :model-value="data.userBed" readonly class="cl_group" label-width="5rem" />
            <van-field label="病区" :model-value="data.userArea" readonly class="cl_group" label-width="5rem" />
            <!-- <van-field label="电话" :model-value="data.phone" readonly class="cl_group" label-width="5rem" v-if="data.phone" /> -->
          </van-cell-group>
        </div>
      </div>
			<div class="cl_two_visitime">
        <img src="../../../assets/et4.png" alt="">
				<div class="cl_di-title">探视时间</div>
				<div class="cl_di-date">{{data.appointVisitTime}}</div>
			</div>
	</div>
</template>

<script>
	// import { ref } from 'vue';
	export default {
		name: 'relsourComponent',
		props: {
			val: {
				required:true,
			},
			data: {
				required: true,
				type: Object,
			},
			unix: {
				required: true,
			},
			image: {
				required: true,
			}
		},
    methods: {
      onFinish() {
        this.$emit('unixtime',true);
      }
    }
	};
</script>

<style scoped>
	.ap {
		width: 95%;
		margin: 6rem auto 0;
    /*border: 1px solid red;*/
    border-radius: 20px;
    background-color: white;
	}
	.ap>p {
		font-size: 2rem;
		margin-left: 1rem;
		font-weight: 600;
	}

	.cl_visitime {
		width: 90%;
		margin: 1rem auto 0;
		display: flex;
		justify-content: space-between;
    align-items: center;
		font-size: 2rem;
		font-weight: 500;
    padding: 1rem 0;
    border-bottom: 0.5px solid #EEEEEE;
	}
  .cl_flex {
    display: flex;
    align-items: center;
  }
	.cl_visitime span:nth-child(2) {
		margin-left: 1rem;
		font-weight: 600;
    font-size: 1.4rem;
    color: #212121;
	}
	.van-count-down {
		font-size: 1.4rem;
		margin-right: -1rem;
    color: #DF1642;

  }
	.cl_max_round {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}
	.cl_max_group {
		width: 55%;
	}
	.cl_round {
		margin: 1rem auto;
		text-align: center;
		transform: translateY(10%);
	}
  .cl_round img {
    width: 7rem;
    height: 7rem;
    border-radius: 1rem;
  }
	.cl_round>p {
		font-size: 2rem;
	}
  /*探视时间*/
	.cl_two_visitime {
		width: 90%;
		margin: 0 auto;
		display: flex;
    align-items: center;
    border-top: 0.5px solid #EEEEEE;
    padding: 1rem 0;
	}
  .cl_two_visitime img {
    width: 1.8rem;
    height: 1.8rem;
    margin-bottom: 0.3rem;
  }
	.cl_two_visitime .cl_di-title {
		font-weight: 600;
    font-size: 1.6rem;
    color: #9E9E9E;
    margin-left: 1rem;
	}
	.cl_two_visitime .cl_di-date {
		color: #36ACC6;
		/*margin-top: 0.5rem;*/
    font-weight: 400;
    font-size: 1.4rem;
    color: #9E9E9E;
    margin-left: 4rem;
	}

  .cl_maxres {
    width: 100%;
    border-radius: 80px;
    /*background-color: #ebeaea;*/
  }
</style>
