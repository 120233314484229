<template>
	<div class="ap">
			<van-nav-bar :title="title" left-arrow @click-left="onClickLeft" class="cl_leftarrow" />
	</div>
</template>

<script>
	export default {
		name: 'titleComponent',
		props: {
			title: {
				required: true,
			}
		},
		
		setup() {
			// 返回上一级
			const onClickLeft = () => history.back();
			return {
				onClickLeft,
			};
		},
	}
</script>

<style scoped>
	.ap {
		width: 100%;
	}

	.cl_leftarrow {
		position: fixed;
		top: 0;
		z-index: 9999;
		width: 100%;
		font-size: 30rem;
		color: rgb(3, 3, 3);
		background-color: rgb(255, 255, 255);
	}
	::v-deep .van-nav-bar .van-icon {
		color: black !important;
	}
</style>
