<template>
	<div class="ap">
		<p>退款信息</p>
		<van-form @submit="onSubmit">
			<van-cell-group inset>
				<van-field label="姓名" v-model="data.userName" colon placeholder="请输入姓名" class="cl_data_field" :rules="[{ required: true, message: '姓名不能为空' }]" />
				<van-field label="床号" v-model="data.userBed" colon placeholder="请输入床号" class="cl_data_field" :rules="[{ required: true, message: '床号不能为空' }]" />
				<van-field label="退款金额" v-model="data.orderAmount" type="digit" colon placeholder="请输入退款金额" :rules="[{ required: true, message: '请输入退款金额' }]" class="cl_data_field" style="color: red;" />
				<van-field label="退款原因" v-model="data.cancelReason" rows="1" autosize type="textarea" placeholder="请输入退款原因" :rules="[{ required: true, message: '退款原因不能为空' }]" colon class="cl_data_field" />
			</van-cell-group>
			<van-button round type="danger" native-type="submit">提交</van-button>
		</van-form>
	</div>
</template>
<van-field v-model="message" 
  />
<script>
import { getcancel } from '@/api/about';
import { Toast } from 'vant';


	export default {
		name: 'reousComponent',
		props: ['val'],
		data() {
			return {
				data: {
					appointId: '',
					userName: '',
					userBed: '',
					orderAmount: '',
					cancelReason: '',
				}
			}
		},
		watch: {
			val() {
				this.data.appointId = this.val;
			}
		},
		methods: {
			onSubmit() {
				getcancel(this.data).then(res => {
					if (res.data.code == 200) {
						Toast.fail('提交成功');
						this.$router.push({path: '/progress', query: {id: this.data.appointId}});
					} else {
						Toast.error('提交失败');
					}
				})
			}
		}
		
	};
</script>

<style scoped>
	.ap {
		width: 90%;
		margin: 6rem auto 0;
		background-color: rgb(241, 241, 241);
		border-radius: 50px;
	}
	.ap>p {
		padding: 2rem 0 0 2rem;
		font-size: 2rem;

	}
	::v-deep .cl_data_field {
		font-size: 1.7rem;
		font-weight: 400;
		height: 6rem;
		background-color: rgb(241, 241, 241);

	}
	::v-deep .van-button {
		width: 70%;
		border: none;
		font-weight: 600;
		font-size: 1.5rem;
		color: white;
		position: fixed;
		left: 50%;
		transform: translate(-50%);
		bottom: 30rem;
	}
</style>
