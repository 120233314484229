<template>
	<div class="ap">
		<div class="cl_ts" v-for="(item,k) in roudata" :key="k"  @click="govisit(item.appointAudit,item.appointId)">
			<div class="cl_one_ts">
				<div class="cl_time"><img src="" alt="" srcset="../../../assets/dsfsdhgndfg.png">{{item.appointVisitTime}}</div>
				<div class="cl_status" v-if="item.appointAudit == 0" style="color: #E68113;">审核中</div>
				<div class="cl_status" v-else-if="item.appointAudit == 1" style="color: blue;">待签到</div>
				<div class="cl_status" v-else-if="item.appointAudit == 2" style="color: blue;">签到成功</div>
				<div class="cl_status" v-else-if="item.appointAudit == 3" style="color: #28A7C2;">待探视</div>
				<div class="cl_status" v-else-if="item.appointAudit == 5" style="color: #E61313;">申请失败</div>
			</div>
			<div class="cl_two_ts">
				<div class="cl_mours">
					<div class="cl_image"><img :src="image" alt=""></div>
					<div class="cl_name"><p>{{item.userName}}</p><p>床号：{{item.userBed}}</p></div>
				</div>
				<div class="cl_res">查看详情<span>></span>
				</div>
          <van-tag class="cl_reservation" plain type="primary" v-if="item.appointAudit == 0">取消预约</van-tag>

			</div>
		</div>
	</div>
</template>

<script>
	import { getavatar } from '@/api/about'
	// import { reactive } from 'vue';
	export default {
		name: 'appoinComponent',
		props: {
			data: Array,
		},
		data() {
			return {
				image: '',
				roudata: [],
			}
		},
		watch: {
			data() {
				this.roudata = this.data;
			}
		},
		created() {
			getavatar().then(res => {
				if(res.data.code == 200) {
					this.image = res.data.data.avatarUrl;
				}
			})
		},
		methods: {
			govisit(val,uid) {
				if (val == 0) {
					this.$router.push({path: '/details',query: {id: uid}});		//审核中
				} else if (val == 1) {
					this.$router.push({path: '/signin',query: {id: uid, stats: false }});		//待签到
				} else if (val == 2) {
					this.$router.push({path: '/signin',query: {id: uid, stats: true }});		//签到成功
				} else if (val == 3) {
					this.$router.push({path: '/tovisit',query: {id: uid}});		//待探视
				} else if (val == 5) {
					this.$router.push({path: '/failed',query: {id: uid}});		//申请失败
				}
			}
		},
	}
</script>

<style scoped>
@import url("//at.alicdn.com/t/font_8d5l8fzk5b87iudi.css");
	.ap {
	}
	.cl_ts {
		width: 94%;
		height: 11rem;
		margin: 0 auto 2rem auto;
		/*background-color: rgb(248, 247, 247);*/
		/*border-radius: 100px;*/
		/* box-shadow: 50px; */
		/*box-shadow: 0.1rem 0.1rem 1rem 0.1rem #d1caca;*/
    background: #FFFFFF;
    border-radius: 8px;
	}
  /*时间及状态*/
	.cl_one_ts {
		width: 95%;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		padding-bottom: 0.5rem;
		padding-top: 0.5rem;
		border-bottom: 1px solid #eaeaea;
		font-size: 1.2rem;
	}
  /*时间*/
  .cl_time {
    font-weight: 400;
    color: #505050;
  }
  .cl_time img {
    width: 1rem;
    height: 1rem;
    margin: 0 1rem;
  }
  /*状态*/
 .cl_status {
   font-weight: 400;
 }
	.cl_two_ts {
		width: 95%;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
    padding-top: 0.5rem;
    align-items: center;
		position: relative;
	}
	.cl_mours {
		display: flex;
    align-items: center;
	}
.cl_image {
  margin-left: 1.5rem;
}
.cl_image img {
		width: 6rem;
		height: 6rem;
		border-radius: 20px;
	}
	.cl_name {
		margin-left: 3.5rem;

	}
	.cl_name p {
    font-size: 1.3rem;
    font-weight: 400;
    color: #333333;

	}
	.cl_res {
		/*margin-top: 3rem;*/
		margin-right: 1rem;
    font-size: 0.9rem;
    font-weight: 400;
    color: #777777;
	}
	.res span {
		font-size: 0.9rem;
		font-weight: 600;
	}
	/* 取消预约 */
	.cl_reservation {
		position: absolute;
		bottom: 0rem;
		right: 0;
		/*border: 1px solid blue;*/
		/*border-radius: 100px;*/
		/*color: blue;*/
		font-size: 1rem;
		/*padding: 0.2rem 0.6rem;*/
	}
</style>
