<template>
  <div class="ap">
    <van-pull-refresh
			v-model="loading"
			success-text="刷新成功"
			@refresh="onRefresh"
			>
      <div class="cl_task" v-for="(v,k) in data" :key="k" @click="getvisit(v.appointAudit,v.appointId,v.appointEvaluate,v.appointVisitTime)">
        <div class="cl_visitime" v-if="v.appointAudit == 1 || v.appointAudit == 2 || v.appointAudit == 3">
          <div><span><i class="iconfont">&#xe687;</i></span><span>待探视</span></div>
          <van-count-down :time="v.surplusTime" format="DD 天 HH 时 mm 分 ss 秒" />
        </div>
        <van-cell-group inset class="cl_max_group">
          <van-field label="预约人" :model-value="v.userName" readonly class="cl_group" />
          <van-field label="电话" :model-value="v.userAccount" readonly class="cl_group" />
          <van-field label="探视时间" :model-value="v.appointVisitTime" readonly class="cl_group" />
        </van-cell-group>
        <div class="cl_sign" v-if="v.appointAudit == -2"><img src="../../../../public/image/refund.png" alt=""></div>  <!-- 已退款  -->
        <div class="cl_visit cl_shoer" v-else-if="v.appointAudit == 0">审核中</div>  <!-- 待审核  -->
        <div class="cl_sign cl_but cl_signed" v-else-if="v.appointAudit == 1" @click="getsignin($event,v.id)">待签到</div>  <!-- 待签到  -->
        <div class="cl_sign" v-else-if="v.appointAudit == 2"><img src="../../../../public/image/success.png" alt=""></div>  <!-- 签到成功  -->
        <div class="cl_visit cl_davist" v-else-if="v.appointAudit == 3">待探视</div>  <!-- 待探视  -->
        <!-- <div class="cl_sign" v-else-if="v.appointAudit == 1"><img src="../../../../public/image/errorsucc.png" alt=""></div>   -->
        <!-- 未签到  -->
        <div class="cl_sign" v-else-if="v.appointAudit == 4 && v.appointEvaluate == false"><div class="cl_but cl_stay" @click="evaluate($event,v.appointId)">待评价</div><img src="../../../../public/image/ended.png" alt=""></div> <!-- 已结束待评价  -->
        <div class="cl_sign" v-else-if="v.appointAudit == 4 && v.appointEvaluate == true"><img src="../../../../public/image/ended.png" alt=""></div> <!-- 已结束已评价  -->
        <div class="cl_visit cl_apply" v-else-if="v.appointAudit == 5">申请失败</div>  <!-- 申请失败  -->


      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
// import { ref } from 'vue';

import { getmine } from '@/api/home';

export default {
  name: 'manvisitComponent',
  data() {
    return {
      data: [],
      loading: false,
    }
  },
  created() {
    this.getmine();
  },
  methods: {
    getvisit(status,val,flag,time) {
      switch (status) {
        case 3:
          this.$router.push({path: '/tovisit',query: {id: val}});  //待探视
          break;
        case 0:
          this.$router.push({path: '/details',query: {id: val}});		//审核中
          break;
        case 1:
          this.$router.push({path: '/signin',query: {id: val, stats: false}});		//待签到
          break;
        case 2:
          this.$router.push({path: '/signin',query: {id: val, stats: true}});	  //签到成功
          break;
        case 5:
          this.$router.push({path: '/failed',query: {id: val}});		//申请失败
          break;
        case 4:
          if(!flag) {
            this.$router.push({path: '/endetails',query: {id: val}});  //待评价
          } else {
            this.$router.push({path: '/ended',query: {
                id: val,
                elavtime: time,
              }});  //已评价
          }
          break;
      }
    },
    getsignin(e,val) {
      e.stopPropagation();
      this.$router.push({path: '/signin',query: {id: val}});  // 待签到
    },
    evaluate(e,val) {
      e.stopPropagation();
      this.$router.push({path: '/evaluate',query: {id: val}}); // 评价
    },
    // 我的预约
    getmine() {
      getmine(0).then(res => {
        if (res.data.code == 200) {
          this.data = res.data.data;
          let new_date = Date.parse(new Date());
          for (let i = 0; i < this.data.length; i++) {
            this.data[i].appointVisitTime = res.data.data[i].appointVisitTime + ' ' + res.data.data[i].appointStartTime;
            let old_date = Date.parse(this.data[i].appointVisitTime);
            this.data[i].surplusTime = old_date > new_date ? old_date - new_date : 0;
          }
        }
      })
    },
    onRefresh() {
      setTimeout(() => {
        this.getmine();
        this.loading = false;
      }, 1000);
    }
  }
};
</script>

<style scoped>
.ap {
  width: 95%;
  margin: 0 auto;
}
.cl_task {
  width: 90%;
  margin: 2rem auto 0;
  background-color: whitesmoke;
  border-radius: 10px;
  padding-top: 1rem;
  padding-bottom: 2rem;
  margin-bottom: 3rem;
  position: relative;
}
.cl_task:last-child {
  margin-bottom: 8rem;
}
.cl_visitime {
  width: 90%;
  margin: 1rem auto 0;
  display: flex;
  justify-content: space-between;
  font-size: 2rem;
  font-weight: 500;
}
.cl_visitime span:nth-child(2) {
  margin-left: 1rem;
}
.van-count-down {
  color: #36ACC6;
  font-size: 1.7rem;
  margin-top: 0.7rem;
}
.cl_group {
  background-color: whitesmoke;
  height: 3rem;
  font-size: 1.7rem;
}
.cl_sign {
  position: absolute;
  bottom: 3rem;
  right: 1.3rem;
}
.cl_visit {
  position: absolute;
  bottom: 3rem;
  right: 1.3rem;
}
.cl_sign .iconfont {
  font-size: 6rem !important;
}
/*待签到，待评价*/
.cl_but {
  color: white;
  border-radius: 200px;
  padding: 0.5rem 1.5rem;
  text-align: center;
  font-size: 1rem;
  position: absolute;
  z-index: 999;
  width: 4rem;
}
.cl_signed {
  background-color: #3967FF;
  bottom: 7rem;
}
.cl_stay {
  background-color: #ffdd2c;
  right: 6rem;
  top: 0rem;
}
.cl_shoer {
  color: rgb(4, 192, 29);
  font-size: 1.3rem;
  bottom: 6rem;
}
.cl_davist {
  color: blue;
  font-size: 1.3rem;
  bottom: 6rem;
}
.cl_apply {
  color: red;
  font-size: 1.3rem;
  bottom: 6rem;
}
</style>
