<template>
	<div class="ap">
		<div class="cl_image"><img :src="img" alt=""></div>
		<div class="cl_information">
			<p>{{data.userName}}</p>
			<p>{{data.userAccount}}</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'centerComponent',
		props: {
			data: Object,
			urlimg: {
				required: true
			}
		},
		data() {
			return {
				img: '',
			}
		},
		watch: {
			urlimg() {
				this.img = this.urlimg;
			}
		}
	}
</script>

<style scoped>
	.ap {
		width: 90%;
		margin: 6rem auto 0;
		display: flex;
		height: 15rem;
		background-color: white;
		border-radius: 20px;
	}
	.cl_image {
		width: 8rem;
		margin-top: 3rem;
		margin-left: 2rem;
	}
	.cl_image img {
		width: 8rem;
	}
	.cl_information {
		color: black;
		font-size: 2rem;
		font-weight: 600;
		margin-left: 3rem;
		margin-top: 1rem;
	}
</style>
