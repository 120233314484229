<template>
	<div class="sap">
    <van-nav-bar :title="title" left-arrow @click-left="onClickLeft" class="cl_leftarrow" />
    <div class="ap">
      <div class="cl_maxres">
        <div class="cl_ribect">
          探视对象
        </div>
        <div class="cl_round">
          <img :src="image" />
          <p>{{data.userName}}</p>
        </div>
        <div class="cl_two_visitime">
          <img src="../../assets/et4.png" alt="" style="width: 1.8rem;height: 1.8rem">
          <div class="cl_title">探视时间</div>
          <div>{{ evaluateTime }}</div>
        </div>
      </div>
      <div class="cl_evaluate">
        <div class="cl_ribect">评价</div>
        <div class="cl_rate">
          <div class="cl_rate_title">{{data.userName}}</div>
          <van-rate
              v-model="data.evaluateStar"
              :size="25"
              color="#FFBB38"
              void-color="whitesmoke"
              void-icon="star"
              readonly
          />
        </div>
        <p class="cl_leave cl_message">{{ data.evaluateInfo }}</p>
        <p class="cl_leave cl_evaltime">{{ data.evaluateTime }}</p>
      </div>
    </div>

    <van-button type="danger" @click="getplayback(id)">探视回放</van-button>

  </div>
</template>

<script>
	import { getinfo,info } from '@/api/home.js'
import { getavatar } from '@/api/about'
	export default {
		name: 'endedComponent',
		data() {
			return {
				id: -1,
				evaluateTime: '',
				image: '',
				title: '已结束',
				data: {}
			}
		},
		created() {
			this.id = this.$route.query.id;
			this.evaluateTime = this.$route.query.elavtime;
			this.inform();
		},
    methods: {
		inform() {
      console.log(this.id)
			getinfo(this.id).then(res => {
        console.log(res)
				if (res.data.code == 200) {
					this.data = res.data.data;
				}
			})
			info().then(res => {
				if (res.data.code == 200) {
					this.data.userName = res.data.data.userName;
				}
			});
			getavatar().then(res => {
				if(res.data.code == 200) {
					this.image = res.data.data.avatarUrl;
				}
			})
		},
      getplayback(val) {
        this.$router.push({path: '/playback',query: {id: val}});
      },

      // 返回
      onClickLeft() {
        this.$router.push({path: '/mytask'});
      }
    }
	}
</script>

<style scoped>
	.sap {
    width: 100%;
    height: 100%;
    border: 1px solid #F9F9F9;
	}

  .cl_leftarrow {
    position: fixed;
    top: 0;
    z-index: 9999;
    width: 100%;
    font-size: 30rem;
    color: rgb(3, 3, 3);
    background-color: rgb(255, 255, 255);
  }
  ::v-deep .van-nav-bar .van-icon {
    color: black !important;
  }
  .ap {
    width: 95%;
    margin: 5.5rem auto 0;
    /*border: 1px solid red;*/
    border-radius: 80px;
    font-size: 1.5rem;
  }
  /*探视对象*/
  .cl_maxres {
    width: 95%;
    margin: 0 auto;
    border-radius: 50px;
    background-color: white;
    height: auto;
    box-sizing: border-box;
  }
  .cl_ribect {
    font-size: 1.4rem;
    padding: 0.7rem 0;
    border-bottom: 0.5px solid #EEEEEE;
    width: 90%;
    margin: 0 auto;
    color: #212121;
  }
  .cl_round {
    width: 90%;
    margin: 1rem auto 2rem;
    text-align: left;
    display: flex;
    transform: translateY(10%);
  }
  .cl_round img {
    width: 7rem;
    height: 7rem;
    border-radius: 1rem;
  }
  .cl_round>p {
    font-size: 2rem;
    margin-left: 5rem;
  }

  /*探视时间*/
  .cl_two_visitime {
    width: 90%;
    /*box-sizing: border-box;*/
    padding: 0.7rem 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    background-color: white;
    border-top: 0.5px solid #EEEEEE;
  }
  .cl_title {
    font-size: 1.4rem;
    color: #212121;
    margin-left: 1rem;
  }
  .cl_two_visitime div:last-child {

    margin-left: 7rem;
    font-weight: 400;
    font-size: 1.4rem;
    color: #9E9E9E;
  }

  /*评价*/
  .cl_evaluate {
    width: 95%;
    margin: 1rem auto 0;
    border-radius: 50px;
    background-color: white;
    height: auto;
    padding: 1.7rem 0 1rem;
    box-sizing: border-box;
  }
  /*评分*/
  .cl_rate {
    width: 90%;
    box-sizing: border-box;
    margin: 1rem auto 0;
    /*padding: 1rem 0;*/
    display: flex;
    justify-content: left;
    align-items: center;
  }
  .cl_rate_title {
    font-weight: 400;
    margin-right: 2rem;
    font-size: 1.6rem;
    line-height: 28px;
    color: #212121;
  }

  /*评价*/
  .cl_leave {
    /*border: 1px solid red;*/
    width: 90%;
    margin: 1rem auto;
  }
  .cl_message {
    text-indent: 2em;
    font-size: 1.4rem;
    font-weight: 400;
    color: #616161;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .cl_evaltime {
    font-size: 1.2rem;
    color: #9E9E9E;
  }


  ::v-deep .van-button {
    width: 90%;
    border: none;
    font-weight: 600;
    font-size: 1.5rem;
    color: white;
    position: fixed;
    left: 50%;
    transform: translate(-50%);
    bottom: 15rem;
    background: #FFBB38;
  }
</style>
